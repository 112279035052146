import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Customer } from '../api/customer';

@Injectable()
export class CustomersService {

    constructor(private http: HttpClient) { }

    public getCustomers() {
        return this.http.get<any>(environment.api+'/get-all-users')
            .toPromise()
            .then(res => res.data as Customer[])
            .then(data => data);
    }

    public updateStatusCustomer(customer) {
        
        return this.http.patch<any>(environment.api+'/update-status-user', customer)
            .toPromise()
    }
}
