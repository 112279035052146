import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        this.model = [
            
            {
                label: 'Inicio',
                items: [
                    { label: 'Solicitudes', icon: 'pi pi-fw pi-id-card', routerLink: ['/claims'] },
                    { label: 'Usuarios', icon: 'pi pi-fw pi-user', routerLink: ['/users'] },
                    { label: 'Cerrar Sesión', icon: 'pi pi-fw pi-sign-out', routerLink: ['/auth/logout'] }
                ]
            },
        ];
    }
}
