import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Claim } from '../api/claim';
import { environment } from 'src/environments/environment';

@Injectable()
export class ClaimsService {

    constructor(private http: HttpClient) { }

    public getClaims() {
        return this.http.get<any>(environment.api+'/get-all-claims')
            .toPromise()
            .then(res => res.data as Claim[])
            .then(data => data);
    }

    public updateStatusClaim(claim) {
        
        return this.http.patch<any>(environment.api+'/update-status-claim', claim)
            .toPromise()
    }
}
