import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { ClaimsService } from './demo/service/claims.service';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { CustomersService } from './demo/service/customers.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerInterceptor } from './demo/interceptor/spinner.interceptor';

@NgModule({
    declarations: [
        AppComponent, NotfoundComponent
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        AuthModule.forRoot({
            domain: environment.domain,
            clientId: environment.clientId,
            authorizationParams: {
              redirect_uri: window.location.origin
            }
          }),
          NgxSpinnerModule
    ],
    providers: [
        // { provide: LocationStrategy, useClass: HashLocationStrategy },
        ClaimsService, CustomersService,
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
